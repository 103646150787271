import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import Sticky from "react-sticky-el"

import SideBarMenu from "../sidebar/sidebar"

import "./header.module.css"
import "./header.css"

import Logo from "../../images/logo.png"

const Header = () => (
	<header>
		<Sticky
			id="sticky-wrapper"
			className="sticky-wrapper"
			stickyClassName="is-sticky"
			stickyStyle={{ transform: "unset", zIndex: "999" }}
		>
			<Nav className="navigation-clean" style={{ zIndex: "1" }}>
				<div className="container">
					<div className="row w-100">
						<div className="col-md-3 col-3 col-sm-3">
							<Link className="navbar-brand logo-brand" to="/">
								<img
									src={Logo}
									effect="blur"
									className="img-fluid"
									alt="The Helping Hand Co"
								/>
							</Link>
						</div>
						<div className="col-md-9 col-9 col-sm-9 float-right">
							<SideBarMenu />
						</div>
					</div>
				</div>
			</Nav>
		</Sticky>
	</header>
)

export default Header
