import React from "react"
// import { Link } from "gatsby"
import "./footer.css"

const footer = () => (
	<div className="bg-green py-5">
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-md-6 col-sm-12">
						<p className="text-white">© Copyright 2020 | The Helping Hand</p>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12">
						<p className="text-white float-right">
							Designed & Powered by{" "}
							<a href="https://webriq.com/" alt="WebriQ">
								WebriQ
							</a>
						</p>
					</div>
				</div>
			</div>
		</footer>
	</div>
)

export default footer
