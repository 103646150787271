import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"

import Logo from "../../images/logo.png"

export default () => (
	<div className="navigation">
		<img className="img-fluid" src={Logo} alt="Site Logo" />
		<Nav className="flex-column">
			<Nav.Item className="text-green sidebar-menu">
				<Link to="/">Home</Link>
			</Nav.Item>
			<Nav.Item className="text-green sidebar-menu">
				<Link to="/#about">About Us</Link>
			</Nav.Item>
			<Nav.Item className="text-green sidebar-menu">
				<Link to="/#mission">Our Mission</Link>
			</Nav.Item>
			<Nav.Item className="text-green sidebar-menu">
				<Link to="/#discounts">Discounts & Deals</Link>
			</Nav.Item>
			<Nav.Item className="text-green sidebar-menu">
				<Link to="/#testiments">Testimonials</Link>
			</Nav.Item>
		</Nav>
	</div>
)
